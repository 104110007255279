import React, { Fragment } from "react";
import "../assets/sass/style.scss";

import Seo from "../blocks/seo/Seo";

import PageTitleGeneric from "../blocks/page-title/PageTitleGeneric";

// Content
import Typography from "../blocks/ui/content/typography/Typography";
import Icon from "../blocks/ui/content/icon/Icon";
import Image from "../blocks/ui/content/image/Image";

// Components
import Alert from "../blocks/ui/components/Alert";
import Button from "../blocks/ui/components/Button";
import UILink from "../blocks/ui/components/Link";
import Card from "../blocks/ui/components/Card";
import Form from "../blocks/ui/components/Form";
import ListGroup from "../blocks/ui/components/ListGroup";
import Modal from "../blocks/ui/components/Modal";
import UINav from "../blocks/ui/components/Nav";
import Scroll from "../blocks/ui/components/Scroll";

// Utilities
import Background from "../blocks/ui/utilities/Background";

// Sticky
import StickyBox from "react-sticky-box";

// Sidebar
import Sidebar from "../blocks/ui/sidebar/Sidebar";

const UserInterface = () => {
  return (
    <Fragment>
      <Seo
        title="User Interface"
        robots="noindex, nofollow"
        bodyAttributes={{
          class: "ui bg-fixed bg-line",
        }}
      />

      <header id="header" className="site-header">
        <div className="wrapper d-flex justify-content-between">
          <div className="align-self-center">
            <a
              title="Logo"
              className="logo primary-logo transform-scale-h"
              href="/"
            >
              <img src="assets/img/logo/logo.svg" alt="Logo" />
            </a>
          </div>
        </div>
      </header>

      <main id="main" className="site-main">
        <PageTitleGeneric title="UI" />

        <section id="examples" className="block spacer p-top-xl">
          <div className="wrapper">
            <div className="row gutter-width-lg">
              <div className="col-xl-8 col-lg-8 col-md-8 order-xl-0 order-lg-0 order-md-0 order-1">
                <div id="content" className="block">
                  <h1 className="small">Content</h1>

                  <Typography />

                  <Icon />

                  <Image />
                </div>

                <div id="components" className="block spacer m-top-xl">
                  <h1 className="small">Components</h1>

                  <Alert />
                  <Button />
                  <UILink />
                  <Card />
                  <Form />
                  <ListGroup />
                  <Modal />
                  <UINav />
                  <Scroll />
                </div>

                <div id="utilities" className="block spacer m-top-xl">
                  <h1 className="small">Utilities</h1>

                  <Background />
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-4 order-xl-1 order-lg-1 order-md-1 order-0"
                id="sticky"
              >
                <StickyBox>
                  <Sidebar />
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer id="footer" className="site-footer">
        <div className="wrapper no-space">
          <div className="row">
            <div className="col-xl-4 col-lg-4 align-self-center">
              <div className="copyright">
                <p>
                  © 2020 Portfolio Template By{" "}
                  <a href="https://www.adveits.com">Adveits</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default UserInterface;
